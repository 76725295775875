import SubHeader from "@/components/SubHeader";
import axios from "@/axios";
import { LicenseInfo, AssignAnalystCard } from "../components/index";
import { assignedTenantIcon, licenseDetailsIcon } from "@shared/icons.js";
const ModalConfirm = () => import("@shared/modal-confirm");

export default {
    name: "licence-details",
    components: {
        SubHeader,
        LicenseInfo,
        AssignAnalystCard,
        ModalConfirm,
    },
    props: {},
    data: () => ({
        clientId: null,
        loadingStates: {
            fetchingDetails: false,
            submit: false,
            delete: false,
        },
        sections: [
            {
                name: "license-details",
                label: "License Details",
                disabled: false,
                component: LicenseInfo,
                show: true,
                icon: licenseDetailsIcon,
            },
            {
                name: "license-analyst",
                label: "Assigned Analyst",
                disabled: true,
                component: AssignAnalystCard,
                show: true,
                icon: assignedTenantIcon,
            },
        ],
        licenseData: {
            licenseDetails: {
                name: "",
                description: "",
                validity: 0,
            },
            licenseAnalyst: [],
        },
        serchValues: {
            analyst: "",
        },
        activeSection: null,
        customBreadCrumbs: [],
        licenseId: null,
        analystListsHeader: [{ name: "User Name" }],
    }),

    computed: {
        getActiveSection() {
            return this.activeSection || this.getSections[0];
        },
        getSections: {
            get() {
                return this.sections;
            },
            set(val) {
                this.sections = val;
            },
        },
        getUserList() {
            return this.licenseData.licenseAnalyst?.filter((el) => el.user_name?.toLowerCase()?.includes(this.serchValues.analyst.toLowerCase()));
        },
    },
    watch: {
        $route() {
            this.updateList();
        },
    },
    async mounted() {

        this.updateList();
        this.checkActiveTab();
    },
    methods: {

        updateList() {
            if (this.$route.name === "license-details") {
                this.getSections = this.getSections.map((el) => ({ ...el, disabled: false }));
            }
        },

        async sectionClick(section) {
            if (section.name === this.activeSection.name) return;
            this.activeSection = section;
            this.$router.push({ params: { tab: section.name } });
            this.customBreadCrumbs = [{ name: section.label }];
            await this.getAPIDetails(section.name);
            this.setBredCrumbs();
        },

        async checkActiveTab() {
            let active_tab = this.$route.params.tab;
            let query_data = this.$route.query;
            active_tab ? this.$router.push({ params: { tab: active_tab }, query: query_data }) : this.$router.push({ params: { tab: "license-details" }, query: query_data });
            let tabData = this.getSections.find((el) => el.name === active_tab);
            this.activeSection = tabData ? tabData : this.getSections[0];
            if (this.$route.name === "license-details" && this.$route.params.id) {
                this.licenseId = this.$route.params.id;
                await this.getAPIDetails(this.activeSection.name);
            }
            this.setBredCrumbs();
        },

        setBredCrumbs(crumb) {
            this.customBreadCrumbs = [];

            this.customBreadCrumbs = [{ name: this.activeSection.label }];
            if (crumb) {
                this.customBreadCrumbs.push({ name: crumb });
            }
        },

        async getAPIDetails(section_name) {
            let apiMapper = {
                "license-details": this.fetchLicensePackDetails,
                "license-analyst": this.getAnalystData,
            };
            await apiMapper[section_name]();
        },

        async fetchLicensePackDetails() {
            this.loadingStates.fetchingDetails = true;
            try {
                let { data } = await axios.get(`/license-pack/client/get-license-user-list/${this.licenseId}`);
                this.licenseData.licenseDetails = data[0] || null;
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.fetchingDetails = false;
        },

        handleFilterResult({ data, type }) {
            this.serchValues[type] = data;
        },

        async getAnalystData() {
            this.loadingStates.fetchingDetails = true;
            try {
                let userLists = await this.getAllUser();
                await this.fetchLicensePackDetails();
                let assignedTenantLists = await this.licenseData.licenseDetails.users;
                userLists = userLists?.map((user) => {
                    for (let i of assignedTenantLists) {
                        if (i.user_id === user.id) {
                            return { ...user, checked: true };
                        }
                    }
                    return { ...user, checked: false, };
                });
                this.licenseData.licenseAnalyst = userLists;
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.fetchingDetails = false;
        },

        async getAllUser() {
            try {
                let clientId = this.$store.getters.getClientId;
                if (!this.$store.getters.getClientId || this.$store.getters.getClientId == null) {
                    let clientData = await axios.post(`client-auth/init`, {});
                    clientId = clientData?.data?.client_id;
                }
                let { data } = await axios.get(`/user/client/${clientId}`);
                return data.map((el) => ({ id: el.id, user_name: el.user_name })) || [];
            } catch (error) {
                console.log(error, "errror");
            }
        },

        async assigAnalystUser() {
            this.loadingStates.submit = true;
            try {
                let payload = {
                    license_pack_id: this.licenseId,
                    users: this.getUserList.filter((el) => el.checked).map((user) => (user.id)),
                };
                let { data } = await axios.post(`/license-pack/client/allocate-user`, payload);
                this.$toast.success(data.message || "Analyst assigned");
            } catch (error) {
                if(Array.isArray(error.response.data.detail)) {
                    this.$toast.error(error.response ? error.response.data.detail?.[0]?.msg : 'Not able to assign license!')
                } else {
                    this.$toast.error(error.response ? error.response.data.detail : 'Not able to assign license!')
                }
            }
            this.loadingStates.submit = false;
        },
        selectAllAnalyst(event) {
            if (event) {
                this.licenseData.licenseAnalyst = this.licenseData.licenseAnalyst.map((el) => ({ ...el, checked: event }));
            } else {
                this.licenseData.licenseAnalyst = this.licenseData.licenseAnalyst.map((el) => ({ ...el, checked: event }));
            }
        }
    },
};
