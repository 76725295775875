<template>
    <div class="flex flex-col gap-6 justify-between flex-1">
        <Card :showActionBtn="licenseData.licenseDetails.id" title="License Details">
            <div v-if="loadingStates.fetchingDetails" class="py-20 flex justify-center items-center bg-white rounded-lg" hideTitle>
                <Loader />
            </div>
            <div v-else>
                <div class="grid grid-cols-3 gap-x-4 w-full p-4">
                <FormulateInput :disabled='true' class="w-full disableInput" v-model="licenseData.licenseDetails.license_pack_name" label="License Name" placeholder="License Name" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" />
                <FormulateInput :disabled='true' class="w-full disableInput" v-model="licenseData.licenseDetails.description" label="License Description" placeholder="Enter Description" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" />
                <div class="flex flex-col gap-1">
                    <label>Validity in Days</label>
                    <div class="flex justify-center items-center h-10 border rounded-md disableInput">
                        <div class="bg-primary-300 text-base-content h-full rounded-l-md flex items-center justify-center px-4">Days</div>
                        <input :disabled='true'  min=1 max=377 type="text" v-model="licenseData.licenseDetails.validity" class="flex-1 px-4 w-full h-full rounded-r-lg text-md text-gray-700" placeholder="Validity in Days" />
                    </div>
                </div>
                </div>
                <div class="grid grid-cols-3 gap-x-4 w-full p-4">
            <FormulateInput :disabled='true' class="w-full disableInput" v-model="licenseData.licenseDetails.client_license_count" label="Number of License" placeholder="License Name" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" />
            <FormulateInput :disabled='true' class="w-full disableInput" v-model="licenseData.licenseDetails.alloted_license_count" label="Alloted License" placeholder="License Name" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" />
            <FormulateInput :disabled='true' class="w-full disableInput" v-model="licenseData.licenseDetails.balanced_license_count" label="Balanced License" placeholder="License Name" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" />
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "@shared/components/card";
import Loader from "@shared/loader";
import { isNumber } from "@/plugins/functions";

export default {
    name: "license-info-card",
    components: {
        Card,
        Loader,
    },
    props: {
        licenseData: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    computed: {},
    async mounted() {
    },
    methods: {
        isNumber,
    },
};
</script>

<style lang="scss" scoped>
    ::v-deep {
  .disableInput {
    input {
      background: #f9fafb !important;
      cursor: not-allowed;
    }
  }
  .disableDropdown {
    background: #f9fafb !important;
    cursor: not-allowed;
  }
}
</style>
